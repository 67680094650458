import CardWithArrow from "@components/BasicComponents/Cards/CardWithArrow/CardWithArrow"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./CareersHiring.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
}

const CareersHiring = (props: Props) => {
	return (
		<section>
			<Separator size={128} />
			<ContainerWrapper>
				<Title content={props.data.sectionTitle} size={"XL"} center />
				<Separator className={styles.cardContainer}>
					{props.data.mainContent?.map(
						(
							element: AdvancedSideAssetMainContent,
							index: number,
						) => {
							return (
								<CardWithArrow
									key={index}
									className={styles.cardBox}
									data={element}
									id={index + 1}
									withImage={false}
								/>
							)
						},
					)}
				</Separator>
			</ContainerWrapper>
		</section>
	)
}

export default CareersHiring
