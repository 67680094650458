import CountsCard from "@components/BasicComponents/Cards/CountsCard/CountsCard"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./CareersCounts.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
	flex?: string
	asRoadMap?: boolean
	withoutAsset?: boolean
}

const CareersCounts = (props: Props) => {
	return (
		<section>
			<ContainerWrapper>
				<div className={styles.container}>
					{props.data.mainContent?.map(
						(
							element: AdvancedSideAssetMainContent,
							index: number,
						) => {
							return (
								<CountsCard
									key={index}
									className={styles.countBox}
									data={element}
									size={"M"}
								/>
							)
						},
					)}
				</div>
			</ContainerWrapper>
			<Separator size={128} />
		</section>
	)
}

export default CareersCounts
