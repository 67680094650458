import {AssetType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./VideoBackground.module.scss"

type Props = {
	desktop: AssetType
	mobile?: AssetType
}

const VideoBackground = (props: Props) => {
	return (
		<>
			<video
				className={`${styles.videoBackground} ${
					props.mobile ? styles.withoutMobile : ""
				}`}
				autoPlay
				loop
				muted
			>
				<source src={props.desktop.src} type={"video/mp4"} />
			</video>
			{props.mobile && (
				<video
					className={`${styles.videoBackground} ${styles.mobile}`}
					autoPlay
					loop
					muted
				>
					<source src={props.mobile.src} type={"video/mp4"} />
				</video>
			)}
		</>
	)
}

export default VideoBackground
