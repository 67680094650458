import Description from "@components/CustomTypography/Description/Description"
import Title from "@components/CustomTypography/Title/Title"
import {AdvancedSideAssetMainContent} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./HoverCard.module.scss"

type Props = {
	data: AdvancedSideAssetMainContent
	className?: string
}

const HoverCards = (props: Props) => {
	return (
		<div className={`${styles.cardContainer} ${props.className}`}>
			<div className={styles.cardContentBox}>
				<Title
					className={styles.cardtitle}
					content={props.data.title}
					fontWeight={600}
					size={"M"}
					type={"2"}
				/>
				<Description
					className={styles.cardDesc}
					content={props.data.content}
					size={"S"}
				/>
			</div>
		</div>
	)
}

export default HoverCards
