import Content from "@components/CustomTypography/Content/Content"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Separator, {
	SeparatorType,
} from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {AdvancedSideAssetMainContent} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./CardWithArrow.module.scss"

type Props = {
	id: number
	data: AdvancedSideAssetMainContent
	container?: SeparatorType
	className?: string
	withImage: boolean
}
const CardWithArrow = (props: Props): JSX.Element => {
	const gradation = {
		0: styles.arrow1,
		1: styles.arrow2,
		2: styles.arrow3,
		3: styles.arrow4,
		4: styles.arrow5,
		5: styles.arrow6,
	}
	// @ts-expect-error
	const arrowStyles = `${styles.arrows} ${gradation[props.id] || ""} ${
		props.withImage ? styles.withImage : ""
	}`

	return (
		<Separator
			className={`${styles.cardContainer}  ${props.className}`}
			withoutMeasure
			{...props.container}
		>
			<div className={arrowStyles}>
				<div className={styles.insideContainer}>
					{props.withImage && props.data.preview && (
						<NewResponsiveImage
							data-testid={"CardWithArrowImage"}
							{...props.data.preview}
							containerClassName={styles.imageContainer}
							imageClassName={styles.image}
						/>
					)}
					{!props.withImage && (
						<Title
							className={styles.insideTitle}
							content={props.data.upperTitle}
							size={"S"}
							center
						/>
					)}
				</div>
			</div>
			<div style={{padding: "0 5%"}}>
				<Content
					data={props.data}
					size={"S"}
					without={{upperTitle: true}}
				/>
			</div>
		</Separator>
	)
}

export default CardWithArrow
