import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import ContentAssetSwitcher from "@components/Switchers/ContentAssetSwitcher/ContentAssetSwitcher"
import {AdvancedSideAssetModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

export type BottomSectionType = {
	data: AdvancedSideAssetModuleType
}

const BottomSection = (props: BottomSectionType) => {
	return (
		<section>
			<Separator size={128}>
				<ContainerWrapper>
					<ContentAssetSwitcher
						data={props.data}
						direction={"left"}
					/>
				</ContainerWrapper>
			</Separator>
		</section>
	)
}

export default BottomSection
