import ImageTextCard from "@components/BasicComponents/Cards/ImageTextCard/ImageTextCard"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./CareersFeature.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
	flex?: string
	asRoadMap?: boolean
	withoutAsset?: boolean
}

const CareersFeature = (props: Props) => {
	return (
		<section>
			<Separator>
				<ContainerWrapper>
					<Title
						content={props.data.sectionTitle}
						size={"XL"}
						center
					/>
					<Separator />
					<div className={styles.cardContainer}>
						{props.data.mainContent?.map(
							(
								element: AdvancedSideAssetMainContent,
								index: number,
							) => {
								return (
									<ImageTextCard
										key={index}
										className={styles.cardBox}
										data={element}
										imageHeight={72}
										imageWidth={72}
										size={"S"}
									/>
								)
							},
						)}
					</div>
				</ContainerWrapper>
			</Separator>
		</section>
	)
}

export default CareersFeature
