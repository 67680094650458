import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import ContentAssetSwitcher from "@components/Switchers/ContentAssetSwitcher/ContentAssetSwitcher"
import {AdvancedSideAssetModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

type Props = {data: AdvancedSideAssetModuleType}

const InBriefSection = (props: Props) => {
	return (
		<section>
			<Separator>
				<ContainerWrapper>
					<ContentAssetSwitcher
						data={props.data}
						direction={"right"}
					/>
				</ContainerWrapper>
			</Separator>
		</section>
	)
}

export default InBriefSection
