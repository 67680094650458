import PaginationWithFiltes from "@components/BasicComponents/PaginationWithFiltes/PaginationWithFiltes"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import ButtonComponent from "@components/Theme/Button/Button"
import {useState} from "react"

import styles from "./PositionSection.module.scss"
import PositionList from "./PositionTable/PositionList"
import {
	PositionListData,
	PositionsSectionComponent,
} from "./PositionsSectionComponent"

const PositionsSection = (props: PositionsSectionComponent) => {
	const [jobs, setJobs] = useState<PositionListData>()

	const changeJobs = (data: any[]) => {
		setJobs(data)
	}

	return (
		<Separator className={styles.positions__container}>
			<Title
				className={styles.title}
				content={props.table.sectionTitle}
				fontWeight={600}
				size={"XL"}
				type={"1"}
			/>

			<PaginationWithFiltes
				changeData={changeJobs}
				data={props.jobs}
				filters={[
					{
						department: {
							options: props.categories,
						},
					},
					{
						location: {
							options: props.locations,
						},
					},
				]}
				postType={"Positions"}
				stylesForFilterBox={`${styles.cardContainer} ${styles.filters_container}`}
				stylesForFilters={styles.cardBox}
				withoutPagination
			>
				{jobs && <PositionList data={jobs} />}
			</PaginationWithFiltes>

			<Separator textAlignCenter withoutMeasure>
				<Separator />
				<Title content={props.table.sectionBottomTitle} size={"M"} />
				<Separator size={16} />
				<ButtonComponent
					buttonType={"primary"}
					link={props.table.sectionBottomButtonLink}
					size={"default"}
					asLink
				>
					{props.table.sectionBottomButton}
				</ButtonComponent>
			</Separator>
		</Separator>
	)
}

export default PositionsSection
