import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import {AssetType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./ImageBackground.module.scss"

type Props = {
	desktop: AssetType
	mobile?: AssetType
}

const ImageBackground = (props: Props) => {
	const mobileImg = props.mobile?.filename ? props.mobile : props.desktop
	return (
		<>
			<NewResponsiveImage
				imageClassName={`${styles.imageContainer}  ${
					props.mobile?.filename ? styles.withoutMobile : ""
				}`}
				withoutContainer
				{...props.desktop}
			/>
			{props.mobile?.filename && (
				<NewResponsiveImage
					imageClassName={`${styles.imageContainer} ${styles.mobile}`}
					withoutContainer
					{...mobileImg}
				/>
			)}
		</>
	)
}

export default ImageBackground
