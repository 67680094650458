// import FullWidthHeader from "@components/BasicComponents/FullWidthHeader/FullWidthHeader"
import FullWidthHeaderAdvanced from "@components/BasicComponents/FullWidthHeader/FullWidthHeaderAdvanced"
import HeadMeta from "@components/Theme/HeadMeta/HeadMeta"
import {getPosts} from "@config/modules"
import getValueOrThrow from "@helpers/getValueOrError"
import {getTranslatedPageData} from "@hooks/PagesData/getTranslatedPageData"
import CareersData from "@jsons/careers.json"
import JobsCategories from "@jsons/jobCategories.json"
import locations from "@jsons/jobCities.json"
import JobsDataSet from "@jsons/jobs.json"
import BottomSection from "@pages/Careers/BottomSection"
import CareersCounts from "@pages/Careers/CareersCounts/CareersCounts"
import CareersFeature from "@pages/Careers/CareersFeature/CareersFeature"
import CareersHiring from "@pages/Careers/CareersHiring/CareersHiring"
import UnderVideoSection from "@pages/Careers/CareersUnderVideo/UnderVideoSection"
import CultureSection from "@pages/Careers/CultureSection"
import InBriefSection from "@pages/Careers/InBriefSection"
import PositionsSection from "@pages/Careers/PositionsSection"
import StoriesListSection from "@pages/Careers/StoriesListSection"
import OurValueSection from "@pages/Careers/ValuesSection/OurValueSection"
import React from "react"

type Props = {}

const careers = (props: Props): any => {
	const pageData = getTranslatedPageData(CareersData, true, true)
	const CareerPage = getValueOrThrow(pageData.AdvancedSideAsset)

	const positionsData = getPosts("JobsModule", {data: JobsDataSet})

	const translatedJobCategories = getPosts("CategoriesModule", {
		data: JobsCategories,
	})

	const transLocaitonsCategories = getPosts("CategoriesModule", {
		data: locations,
	})

	return (
		<>
			<HeadMeta meta={pageData.metaData} />

			{/* <FullWidthHeader
				data={getValueOrThrow(CareerPage[0].mainContent)[0]}
				position={"left"}
				size={"L"}
			/> */}
			<FullWidthHeaderAdvanced
				data={getValueOrThrow(pageData.heroData)}
				withOverlay
			/>
			<UnderVideoSection data={CareerPage[1]} />

			<CultureSection data={CareerPage[2]} />

			<CareersFeature data={CareerPage[3]} />

			<StoriesListSection data={CareerPage[4]} />

			<InBriefSection data={CareerPage[5]} />

			<CareersCounts data={CareerPage[6]} withoutAsset />

			<OurValueSection data={CareerPage[7]} />

			<CareersHiring data={CareerPage[8]} />

			<PositionsSection
				categories={translatedJobCategories}
				jobs={positionsData}
				locations={transLocaitonsCategories}
				table={CareerPage[10]}
			/>

			<BottomSection data={CareerPage[9]} />
		</>
	)
}

export default careers
