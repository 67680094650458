import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Description from "@components/CustomTypography/Description/Description"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {AdvancedSideAssetModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import styles from "./UnderVideoSection.module.scss"

const UnderVideoSection = (props: {data: AdvancedSideAssetModuleType}) => {
	return (
		<section>
			<ContainerWrapper>
				<Separator className={styles.container} textAlignCenter>
					<Title content={props.data.sectionTitle} size={"XL"} />
					<Description content={props.data.sectionSubtitle} />
				</Separator>
			</ContainerWrapper>
		</section>
	)
}

export default UnderVideoSection
