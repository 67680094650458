import ContentAsset from "@components/BasicComponents/ContentAsset/ContentAsset"
import SwiperT from "@components/BasicComponents/Swiper/MySwiper"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Content from "@components/CustomTypography/Content/Content"
import Separator from "@components/CustomTypography/Separator/Separator"
import ButtonComponent from "@components/Theme/Button/Button"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {SwiperSlide} from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

export type StoriesListSectionType = {
	data: AdvancedSideAssetModuleType
}

const StoriesListSection = (props: StoriesListSectionType) => {
	const mainContent = props.data.mainContent
	return (
		<section>
			<Separator>
				<ContainerWrapper style={{overflow: "hidden"}}>
					{/* Title for Careers Stories Page */}
					<Separator textAlignCenter withoutMeasure>
						<Content
							data={{
								title: props.data.sectionTitle,
							}}
							size={"XL"}
						/>
					</Separator>

					<SwiperT
						module={mainContent?.map(
							(
								element: AdvancedSideAssetMainContent,
								index: number,
							) => {
								return (
									<SwiperSlide key={index}>
										<Separator>
											<ContentAsset
												data={element}
												direction={"left"}
											/>
										</Separator>
									</SwiperSlide>
								)
							},
						)}
					/>

					<Separator size={32} textAlignCenter>
						<ButtonComponent
							buttonType={"secondary"}
							link={props.data.sectionBottomButtonLink}
							size={"default"}
							asLink
						>
							{props.data.sectionBottomButton}
						</ButtonComponent>
					</Separator>
				</ContainerWrapper>
			</Separator>
		</section>
	)
}

export default StoriesListSection
