import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import React from "react"

import PositionsSectionComponent from "./Positions/PositionsSection"
import {PositionsSectionComponent as PositionsSectionComponentType} from "./Positions/PositionsSectionComponent"

const PositionsSection = (props: PositionsSectionComponentType) => {
	return (
		<section id={"openPositions"}>
			<ContainerWrapper>
				<div style={{background: "#f5f5f5"}}>
					<PositionsSectionComponent
						categories={props.categories}
						jobs={props.jobs}
						locations={props.locations}
						table={props.table}
					/>
				</div>
			</ContainerWrapper>
		</section>
	)
}

export default PositionsSection
