import HoverCards from "@components/BasicComponents/Cards/HoverCard/HoverCards"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import styles from "./OurValueSection.module.scss"

export type OurValueSectionType = {
	data: AdvancedSideAssetModuleType
}

const OurValueSection = (props: OurValueSectionType) => {
	return (
		<section style={{background: "#f5f5f5"}}>
			<ContainerWrapper>
				<Separator size={128}>
					<Title
						content={props.data.sectionTitle}
						size={"XL"}
						type={"1"}
						center
					/>
					{/* Out Values Cards List */}
					<Separator className={styles.cardsWrapper}>
						{props.data.mainContent?.map(
							(
								element: AdvancedSideAssetMainContent,
								index: number,
							) => {
								return (
									<HoverCards
										key={index}
										className={styles.cardContainer}
										data={element}
									/>
								)
							},
						)}
					</Separator>
				</Separator>
			</ContainerWrapper>
		</section>
	)
}

export default OurValueSection
